@tailwind base;
@tailwind components;
@tailwind utilities;

/* @import url('https://fonts.googleapis.com/css2?family=Playwrite+AU+NSW:wght@100..400&family=Playwrite+CO:wght@100..400&family=Playwrite+CU:wght@100..400&display=swap'); */


@import url('https://fonts.googleapis.com/css2?family=Playfair+Display:ital,wght@0,500..900;1,500..900&display=swap');
body {
  font-family: 'Montserrat', sans-serif;
  font-size: 16px; /* Base font size */
  line-height: 1.6; /* Improve readability */
  color: #333; /* Dark gray for body text */
}
 
h1, h2, h3, h4, h5 {
  font-family: 'Playfair Display', serif; /* Use serif font for headings */
  color: #0c0c0b; 
  margin-bottom: 0em; /* Spacing below headings */
}
.title {
  color: #057dcd;
  margin: 0;
  margin-left: 7px;
  padding: 0;
  font-size: 2.5rem;

  font-family: "Playfair Display", serif;
  font-optical-sizing: auto;
  font-weight: medium;
  font-style: italic;

}
.titletwo{
  color: #ffffff;
  margin: 0;
  margin-left: 2px;
  padding: 0;
  font-size: 1.5rem;

  font-family: "Playfair Display", serif;
  font-optical-sizing: auto;
  font-weight: medium;
  font-style: italic;

}
.custom-li {
  list-style-type: none;
  position: relative;
  padding-left: 1em;
  
}

.custom-li::before {
  content: "✅";
  position: absolute;
  left: -1em;
  
}

.carousel {
  width: 100%;
  height: 100%;

}

@media (min-width: 750px) and (max-width: 1250px) {
  .md\:hidden {
    display: none !important;
  }
  .lg\:hidden {
    display: none !important;
  }
}
.rcw-conversation-container {
  background-color: #123456; /* Change the color to your preference */
}

.rcw-launcher {
  background-color: #123456; /* Change the color to your preference */
}
@keyframes rainbow {
  0% { color: red; }
  14% { color: orange; }
  28% { color: yellow; }
  42% { color: green; }
  57% { color: blue; }
  71% { color: indigo; }
  85% { color: violet; }
  100% { color: red; }
}

.rainbow-icon {
  animation: rainbow 2s infinite;
  margin-right: 8px;
}

.chatnow {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px;
  background-color: #f5f5f5;
  height: 100vh;
}

.chatnow__logo {
  margin-bottom: 20px;
}



.chatnow__title {
  margin-bottom: 20px;
}

.chatnow__form {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 400px;
}

.chatnow__form form {
  display: flex;
  flex-direction: column;
}

.chatnow__form input {
  padding: 10px;
  margin-bottom: 10px;
  border: 1px solid #ddd;
  border-radius: 5px;
}

.chatnow__form button {
  padding: 10px;
  border: none;
  border-radius: 5px;
  background-color: #007bff;
  color: white;
  cursor: pointer;
  width: 100px;
  align-self: flex-end;
  margin-top: 10px;
}
.chatnow__toggle {
  position: fixed;
  right: 20px;
  bottom: 20px;
  background-color: #bde4ff;
  border: none;
  padding: 15px 32px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  border-radius: 50%;
  cursor: pointer;
}

.chatnow__form {
  position: fixed;
  right: 20px;
  bottom: 80px;
  width: 500px; /* Or any width you want */
  height: 400px; /* Or any height you want */
  overflow: auto;
  border: 1px solid #000;
  border-radius: 10px;
  background-color: #fff;
}
.review-card {
  border: 1px solid #ccc;
  padding: 5px;
  
  margin-bottom: 20px;
  border-radius: 5px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease-in-out;
}
.review-card:hover {
  transform: scale(1.01);
}

.review-author {
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 0px;
}

.review-text {
  font-size: 16px;
  padding: 10px ;
}
.review-text-time {
  font-size: 12px;
  padding: 10px ;
}
.google .blue {
  color: #4285F4;
}

.google .red {
  color: #DB4437;
}

.google .yellow {
  color: #F4B400;
}

.google .green {
  color: #0F9D58;
  
}   

.ImagesPage{
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50%;
  height: 70%;

}
.Images{
  width: 200%;
  height: 150%;
  object-fit: cover;
  border-radius: 10px;

  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  transition: transform 0.7s ease-in-out;
}

.fade-in {
  opacity: 1;
  transition: opacity 0.5s ease-in;
}

.fade-in {
  opacity: 0; /* Start with hidden */
}


/* styles.css */

@keyframes fadeIn {
  from { opacity: 0; }
  to { opacity: 1; }
}

@keyframes slideIn {
  from { transform: translateY(-50%); opacity: 0; }
  to { transform: translateY(0); opacity: 1; }
}

.modalContent {
  top: '50%';
  left: '50%';
  right: 'auto';
  bottom: 'auto';
  margin-right: '-50%';
  transform: 'translate(-50%, -50%)';
  width: '70%';
  max-width: '400px';
  height: '90%';
  max-height: '500px';
  padding: '2rem';
  border-radius: '10px';
  box-shadow: '0px 4px 8px rgba(0, 0, 1, )';
  border: '1px solid #ccc';
  overflow: 'hidden';
  
  background: rgba(0, 0, 0, 0.5); 
  color: '#000';
  z-index: '1000';
  position: 'relative';
  display: 'flex';
  flex-direction: 'column';
  justify-content: 'center';
  align-items: 'center';
  text-align: 'center';
  gap: '1rem';
  font-family: 'sans-serif';
  font-size: '1rem';
  font-weight: '400';
  line-height: '1.5';
  letter-spacing: '0.00938em';
  white-space: 'nowrap';
  word-spacing: '0.00938em';
  text-transform: 'none';
}

@media (max-width: 600px) {
  .modalContent {
      width: '70%';
      height: '50%';
      /* max-width: 'none';
      max-height: 'none'; */
  }
}

.modalContent::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5); /* Adjust color and opacity as needed */
  z-index: -1;
}
/* ?buttom animate */
@keyframes pulse{
  0% {
    transform: scale(1);
    color: #000; /* initial color */
  }
  50% {
    transform: scale(1.1);
    color: #e48e0e; /* color at 50% of the animation */
  }
  100% {
    transform: scale(1);
    color: #000; /* color at the end of the animation */
  }
}
.button-animate {
  animation: pulse 2s infinite;
}
/* ?form animate */
@keyframes fadeIn{
  0% {
    opacity: 0;
    color: #56e614; /* initial color */
  }
  50% {
    transform: scale(1.1);
    color: #e48e0e; /* color at 50% of the animation */
  }
  100% {
    opacity: 1;
    color: #0b7e10; /* color at the end of the animation */
  }
}
.form-animate {
  animation: fadeIn 2s ease-in-out;
}
/* ?call animate */
@keyframes shake {
  0% { transform: translate(1px, 1px) rotate(0deg); color: red; }
  10% { transform: translate(-1px, -2px) rotate(-1deg); color: orange; }
  20% { transform: translate(-3px, 0px) rotate(1deg); color: yellow; }
  30% { transform: translate(3px, 2px) rotate(0deg); color: green; }
  40% { transform: translate(1px, -1px) rotate(1deg); color: blue; }
  50% { transform: translate(-1px, 2px) rotate(-1deg); color: indigo; }
  60% { transform: translate(-3px, 1px) rotate(0deg); color: violet; }
  70% { transform: translate(3px, 1px) rotate(-1deg); color: red; }
  80% { transform: translate(-1px, -1px) rotate(1deg); color: orange; }
  90% { transform: translate(1px, 2px) rotate(0deg); color: yellow; }
  100% { transform: translate(1px, -2px) rotate(-1deg); color: green; }
}



/* bloom text */
.bloom-text {
  text-shadow: 0 0 5px rgba(255, 215, 0, 0.5), 
               0 0 10px rgba(255, 215, 0, 0.5), 
               0 0 15px rgba(255, 215, 0, 0.5), 
               0 0 20px rgba(255, 215, 0, 0.5), 
               0 0 25px rgba(255, 215, 0, 0.5);
}

.footer {
  text-align: center;
  font-size: 70%;
  color: #d99d49;
  padding: 10px;
  margin-top: 30px;
  /* text-shadow: 0em 0em 0.2em #c29913; */
  width: 100%;
  height: 100%;
  background-color: #003366;
  
 

}

@keyframes slideInRight {
  0% {
    transform: translateX(100%) translateY(-20%);
    opacity: 0;
  }
  100% {
    transform: translateX(0) translateY(0);
    opacity: 1;
  }
}
