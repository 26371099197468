:root {
    --gold: #d4af37;
    --white: #ffffff;
    --silver: #c0c0c0;
    --soft-gray: #f2f2f2;
    --dark-gray: #333333;
  }

h1, h2, h3 {
    font-family: 'Playfair Display', serif;
    color: var(--gold);
  }
  
  body {
    font-family: 'Lato', sans-serif;
    font-weight: 300;
    color: var(--dark-gray);
  }
  
  
  /* Applying color scheme */
  body {
    background-color: var(--soft-gray);
    color: var(--dark-gray);
  }
  
  .header {
    background-color: var(--dark-gray);
  }
  
  .nav ul li a {
    color: var(--white);
  }
  
  /* button {
    background-color: var(--gold);
    color: var(--white);
    border: 1px solid var(--gold);
  }
  
  button:hover {
    background-color: var(--white);
    color: var(--gold);
  } */
  
  