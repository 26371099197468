

.loader-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: #f9f9f9;
}

.logo-loader {
  width: 200px;
  height: auto;
}

.logo-loader path {
  fill: none;
  stroke: #000;
  stroke-width: 4;
  stroke-dasharray: 1000; /* Adjust to your path length */
  stroke-dashoffset: 1000;
  animation: draw 0s linear infinite;
}

@keyframes draw {
  to {
    stroke-dashoffset: 0;
  }
}
