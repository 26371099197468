.chatnow__button {
    position: fixed;
    right: 1.5rem;
    bottom: 1.5rem;
    background: linear-gradient(135deg, #007aff, #00c6ff);
    color: #fff;
    padding: 1rem;
    border-radius: 50%;
    cursor: pointer;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
    transition: transform 0.2s ease, box-shadow 0.2s ease;
}

.chatnow__button:hover {
    transform: scale(1.15);
    box-shadow: 0 6px 16px rgba(0, 0, 0, 0.25);
}

.notification-dot {
    position: absolute;
    top: -0.3rem;
    right: -0.3rem;
    width: 1.2rem;
    height: 1.2rem;
    background-color: #ff4b4b;
    color: #fff;
    font-size: 0.75rem;
    font-weight: bold;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    box-shadow: 0 0 6px rgba(255, 75, 75, 0.6);
}

.chatnow__form-container {
    position: fixed;
    top: 0;
    right: 0;
    width: 340px;
    max-width: 100%;
    height: 87vh;
    background: #f9fafb;
    border-left: 1px solid #e0e0e0;
    padding: 1.5rem;
    box-shadow: -6px 0 20px rgba(0, 0, 0, 0.1);
    z-index: 1000;
    display: flex;
    flex-direction: column;
    overflow-y: auto;
    transition: transform 0.5s ease, opacity 0.5s ease;
}

@media (max-width: 600px) {
    .chatnow__form-container {
        width: 100%;
        height: 85vh;
    }
}

/* Slide-in animation */
.is-visible {
    animation: slideInFromRight 0.5s ease forwards;
}

@keyframes slideInFromRight {
    from {
        opacity: 0;
        transform: translateX(100%);
    }
    to {
        opacity: 1;
        transform: translateX(0);
    }
}

/* Slide-out animation */
.is-hidden {
    animation: slideOutToRight -0s ease forwards;
}

@keyframes slideOutToRight {
    from {
        opacity: 1;
        transform: translateX(0);
    }
    to {
        opacity: 0;
        transform: translateX(100%);
    }
}


.chatnow__logo {
    display: flex;
    align-items: center;
    gap: 0.75rem;
    margin-bottom: 1rem;
}

.chatnow__logo img {
    width: 3rem;
    height: 3rem;
    border-radius: 50%;
    border: 2px solid #007aff;
}

.chatnow__input, .chatnow__textarea {
    width: 100%;
    padding: 0.75rem;
    margin-bottom: 1rem;
    border: 1px solid #3b6bb4;
    border-radius: 0.5rem;
    font-size: 0.875rem;
    color: #374151;
    outline: none;
    transition: border-color 0.2s ease;
}

.chatnow__input:focus, .chatnow__textarea:focus {
    border-color: #007aff;
}

.chatnow__submit-btn {
    width: 100%;
    padding: 0.75rem;
    background: linear-gradient(135deg, #007aff, #00c6ff);
    color: #fff;
    font-weight: bold;
    border: none;
    border-radius: 0.5rem;
    cursor: pointer;
    transition: background 0.2s ease;
}

.chatnow__submit-btn:hover {
    background: linear-gradient(135deg, #0066d4, #0093e6);
}

.chatnow__success-msg {
    color: #007aff;
    text-align: center;
    font-size: 0.875rem;
    margin-top: 1rem;
}
